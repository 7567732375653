<template>
  <layout width="420">
    <template slot="header">
      {{ title }}
    </template>

    <template>
      <validation-observer ref="form">
        <v-form>
          <form-subheader title="General info" />
          <validation-provider
            v-slot="{ errors }"
            name="Geotag name"
            rules="required"
          >
            <v-text-field
              v-model="state.name"
              label="Geotag name"
              :error-messages="errors"
            />
          </validation-provider>
          <image-uploader
            label="Photo"
            :src="imageUrl"
            @change="handleChangeImage"
            @clear="handleClearImage"
          />
          <form-subheader title="Position" />
          <v-text-field v-model="state.minAltitude" label="Min altitude" />
          <v-text-field v-model="state.maxAltitude" label="Max altitude" />
          <form-subheader title="Description" />
          <v-textarea
            v-model="state.description"
            no-resize
            rows="1"
            auto-grow
          />
        </v-form>
      </validation-observer>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="submit"
      >
        {{ submitBtnTitle }}
      </v-btn>
    </template>
  </layout>
</template>

<script>
import GeotagEdit from './GeotagEdit.vue';
import { computed, onMounted, ref } from '@vue/composition-api';
import { geotagservice } from '@/modules/geotags/api';
import { objectPropertyService } from '@/modules/common/api';
import { usePromise } from 'vue-composable';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'GeotagCopy',
  mixins: [GeotagEdit],
  setup(props, { emit, root }) {
    const title = computed(() => 'Create copy');
    const state = ref({
      schemaId: '',
      name: '',
      description: '',
      imageId: '',
      minAltitude: 0,
      maxAltitude: 0,
      country: '',
      city: '',
      street: '',
      building: '',
      postcode: '',
      room: '',
      enabled: true,
      autoRecalculation: true,
      positionPoints: [],
      positionBaseCoordinate: {
        lat: 0,
        lon: 0
      }
    });

    const form = ref(null);
    const geotag = ref({});

    const handleSubmit = async () => {
      form.value.validate().then(async success => {
        if (!success) {
          return;
        }
        geotag.value = await geotagservice.create({
          name: state.value.name,
          description: state.value.description,
          schemaId: state.value.schemaId,
          enabled: state.value.enabled
        });
        await objectPropertyService.updateValues([
          {
            id: geotag.value.positionPoints.id,
            value: state.value.positionPoints
          },
          {
            id: geotag.value.positionBaseCoordinate.id,
            value: {
              lon: state.value.positionBaseCoordinate.lon || 0,
              lat: state.value.positionBaseCoordinate.lat || 0
            }
          },
          {
            id: geotag.value.infoImageId.id,
            value: state.value.imageId
          },
          {
            id: geotag.value.addressBuilding.id,
            value: state.value.building
          },
          {
            id: geotag.value.addressCity.id,
            value: state.value.city
          },
          {
            id: geotag.value.addressCountry.id,
            value: state.value.country
          },
          {
            id: geotag.value.addressPostcode.id,
            value: state.value.postcode
          },
          {
            id: geotag.value.addressRoom.id,
            value: state.value.room
          },
          {
            id: geotag.value.addressStreet.id,
            value: state.value.street
          },
          {
            id: geotag.value.positionMinAltitude.id,
            value: Number(state.value.minAltitude) || 0
          },
          {
            id: geotag.value.positionMaxAltitude.id,
            value: Number(state.value.maxAltitude) || 0
          },
          {
            id: geotag.value.optionsAutoRecalculation.id,
            value: Boolean(state.value.autoRecalculation)
          }
        ]);

        root.$router.push({
          name: 'geotag_card',
          params: {
            geotagId: geotag.value.id
          }
        });

        emit('close');
      });
    };

    const init = async () => {
      state.value.schemaId = await geotagservice.fetchGeotagSchemaId();
      geotag.value = await geotagservice.fetch(props.geotagId);

      const {
        infoImageId,
        name,
        description,
        enabled,
        addressBuilding,
        addressCity,
        addressCountry,
        addressPostcode,
        addressRoom,
        addressStreet,
        positionMinAltitude,
        positionMaxAltitude,
        optionsAutoRecalculation,
        positionPoints,
        positionBaseCoordinate
      } = geotag.value;

      state.value = {
        ...state.value,
        imageId: infoImageId.value,
        name,
        description,
        enabled,
        building: addressBuilding.value,
        city: addressCity.value,
        country: addressCountry.value,
        postcode: addressPostcode.value,
        room: addressRoom.value,
        street: addressStreet.value,
        minAltitude: positionMinAltitude.value,
        maxAltitude: positionMaxAltitude.value,
        autoRecalculation: optionsAutoRecalculation.value,
        positionPoints: positionPoints.value,
        positionBaseCoordinate: positionBaseCoordinate.value
      };
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onMounted(async () => {
      init();
    });

    return {
      title,
      submit,
      loading,
      state,
      form,
      geotag
    };
  }
};
</script>
